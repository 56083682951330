.dropdown-list {
}
.header_txt {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #001834;
}

.icon {
  color: #8a98a9;
}

.title {
}
.title_bold {
  margin-right: 5px;
  font-weight: 700;
}
