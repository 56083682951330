.dropdown {
  color: white;

  .label {
    color: white;
  }
  .selector {
    &::before {
      border-color: white;
    }
    &::after {
      border-color: white;
    }
  }
  .input_txt {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.state_block {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 4px;

  &.error {
    background-color: #ef4444;
  }
  &.active {
    background: #10b981;
  }
  &.processing {
    background-color: #f59e0b;
  }
  &.draft {
    background-color: #545d67;
  }
  &.inactive {
    background-color: #8a98a9;
  }
   
}
.inside_txt {
  padding: 4px 6px;
  border-radius: 4px;
  color: #ffffff;
  border: 0.5px solid #ffffff;
  &.error {
    background-color: #ef4444;
  }
  &.active {
    background: #10b981;
  }
  &.processing {
    background-color: #f59e0b;
  }
  &.draft {
    background-color: #545d67;
  }
  &.inactive {
    background-color: #8a98a9;
  }
   &.none {
    background-color: #FFFFFF;
    color: black;
  }
}
