.login_page {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-color: #ecf2fa;
  background-image: url("../assets/bmind_bg.svg");
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    position: absolute;
    left: 70px;
    top: 50px;
  }
  .inside_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }
  .login_header {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #001834;
    margin-bottom: 20px;
  }
  .login_box {
    background-color: #ffffff;
    padding: 2rem 2rem;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
  }
  .input_txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8a98a9;
  }
  .forgot_password {
    display: flex;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #001834;
  }
  .no_account {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #001834;
  }
  .register_here {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #001834;
    text-decoration-line: underline;
  }
}
