.form_input {
  .top_label {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #001834;
    padding-bottom: 5px;
  }
  .input_box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 6px;
    background: #ffffff;
    border: 0.5px solid #27415f;
    border-radius: 4px;
  }
}
