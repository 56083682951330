.user {
  .box_bottom_container {
    border-radius: 8px;
    border: 0px;
    width: 50%;
    background-color: #ffffff;

    padding: 20px;
    &.right {
      background-color: #f9fbff;
      margin-bottom: 0px;
    }
  }

  .subheader_txt {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    color: #001834;
  }

  .box_container {
    border: 0px;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    margin-bottom: 32px;
  }

  .header_txt {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #001834;
  }

  .top_label {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #001834;
  }

  .non_editable_field {
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8a98a9;
  }

  .close_icon {
    cursor: pointer;
  }
}
