.search {
  background: #ffffff;
}

.input_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 12px;
  gap: 10px;
  width: 200px;
  height: 34px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
  border-radius: 4px;
}
