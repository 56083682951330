.list {
  flex-grow: 1;
}
.item_list {
  // background-color: red;
  margin-left: -5px;
  height: 34px;
  border-bottom: 0.25px solid #8a98a9;
}
.grid_container {
  & .long {
    height: 150px;
  }
  & .short {
    height: 136px;
  }

  filter: drop-shadow(0px 2px 8px rgba(39, 65, 95, 0.2));
}

.edit_icon {
  margin-right: 8px;
  cursor: pointer;
  color: #8a98a9;
}

.trash_icon {
  color: #8a98a9;
}

.status_label {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;

  border: 0.5px solid #27415f;
  border-radius: 4px;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #27415f;
}

.plan_label {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 6px;
  // width: 125px;
  height: 18px;
  border: 0.5px solid #27415f;
  border-radius: 4px;
}

.role_label {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 6px;
  margin-left: 5px;
  margin-right: 5px;
  height: 18px;
  border: 0.5px solid #27415f;
  border-radius: 4px;
}

.role_label_su {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 6px;
  margin-left: 5px;
  margin-right: 5px;
  height: 18px;
  border: 0.5px solid #27415f;
  border-radius: 4px;
  background-color: #F8C700;
}

.role_label_owner {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 6px;
  margin-left: 5px;
  margin-right: 5px;
  height: 18px;
  border: 0.5px solid #FFFFFF;
  border-radius: 4px;
  background-color: #27415f;
}

.plan_label_txt {
  padding-top: 5px;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: #27415f;
  text-transform: uppercase;
}

.plan_label_su_txt {
  padding-top: 5px;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: #27415f;
  text-transform: uppercase;
}

.plan_label_owner_txt {
  padding-top: 5px;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: #FFFFFF;
  text-transform: uppercase;
}
