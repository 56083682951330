.kpi_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  height: 100%;
  box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
  border-radius: 8px;
}

.kpi_card_button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: #27415f;
  box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
  border-radius: 8px;
}

.kpi_card_macro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 32px;
  height: 214px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
  border-radius: 8px;
}

.kpi_top_button_txt {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #eff2f6;
}
.kpi_bottom_button_txt {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  color: #ffffff;
}
.kpi_top_text {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #8a98a9;
}

.kpi_number_bottom_text {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #001834;
}

.kpi_str_bottom_text {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #27415f;
  word-break: break-all;
}

.separator {
  width: 0px;
  height: 142px;
  border: 0.25px solid #8a98a9;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.mini_card_top {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #27415f;
}

.mini_card_bot {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #8a98a9;
}

.card_icon {
  color: #ffffff;
  cursor: pointer;
}
.filter_selected {
  cursor: pointer;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 3px;
  background: #27415f;
  border: 0.5px solid #27415f;
  border-radius: 100px;
}
.filter {
  cursor: pointer;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 3px;
  border: 0.5px solid #8a98a9;
  border-radius: 100px;
}
.filter_selected_txt {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.filter_txt {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #8a98a9;
}

.quarter_txt {
  min-width: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #001834;
}

.quarter_pre_txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8a98a9;
  margin-right: 8px;
}
