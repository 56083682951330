.breadcrumb {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  color: #001834;
  &.current {
    font-weight: bold;
  }
}

.subheader_txt {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #001834;
  padding-bottom: 30px;
}

.header_bottom_txt {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #001834;
  padding-bottom: 8px;
}

.subheader_bottom_txt {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #001834;
  padding-bottom: 4px;
}

.bottom_txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  color: #001834;
  padding-bottom: 30px;
}

.box {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  &.audio {
    background: #f9fbff;
  }
  &.txt {
    background: #ffffff;
  }
}

.top_label {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #001834;
  padding-bottom: 5px;
}

.box_selector {
  display: flex;
  justify-content: space-between;
}

.selector {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.version_history_txt {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #001834;
}
