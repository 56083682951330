.home_page {
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/bmind_bg.svg");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  // background-image: url("../assets/bmind_bg.svg");
  // width: 100vw;
  // height: 100vh;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // mix-blend-mode: color;
  // opacity: 0.35;
  padding-top: 80px;
  padding-left: 10% !important;
  padding-right: 10% !important;
  .picker_input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 12px;
    gap: 10px;
    height: 34px;
    background: #ffffff;
    border: 0.5px solid #27415f;
    border-radius: 4px;
  }
  .datepicker_label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #001834;
  }
}

.header_txt {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #001834;
}

.subheader_txt {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #001834;
}

.react-datepicker-wrapper {
  margin-right: 10px;
}

.react-datepicker__close-icon {
  padding-right: 15px !important;
}
.clear_button {
  padding-right: 15px;

}
.react-datepicker__close-icon::after{
  color: #8A98A9 !important;
  background-color: #ffffff !important;
  font-size: 25px !important;
  // height: 30px !important;
  // width: 30px !important;
}
