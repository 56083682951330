.button {
  width: 144px;
  height: 45px;
  background: #001834;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 8px;
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
  }
}
.button_txt {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;

  align-items: center;
  text-align: center;
  color: #ffffff;
  flex: none;
}
.button_txt_secondary {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #001834;
  flex: none;
  &:hover {
    color: #ffffff;
  }
}
.button_clicked {
  width: 144px;
  height: 45px;
  background: #27415f;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 8px;
  position: absolute;
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
  }
}
.button:hover {
  background-color: #ffc700;
}
