.customTable {
}

.icon {
  vertical-align: auto;
  margin-right: 8px;
  margin-top: 5px;
}

.header_text {
  font-size: 11px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #001834;
}

.row_text {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #27415f;
}

.state_block {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 4px;

  &.error {
    background-color: #ef4444;
  }
  &.active {
    background: #10b981;
  }
  &.processing {
    background-color: #f59e0b;
  }
  &.draft {
    background-color: #545d67;
  }
  &.inactive {
    background-color: #8a98a9;
  }
}
