.subheader_txt {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #001834;
}

.box_container {
  border: 0px;
  width: 50%;
  box-shadow: 0px 2px 8px rgba(39, 65, 95, 0.2);
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 0px;
}

.box_bottom_container {
  border-radius: 8px;
  border: 0px;
  width: 50%;
  background-color: #ffffff;
  margin-bottom: 0px;
  padding: 20px;
  &.right {
    background-color: #f9fbff;
  }
}

.header_txt {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #001834;
}

.top_label {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #001834;
}

.non_editable_field_ma {
  padding-top: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8a98a9;
}

.close_icon {
  cursor: pointer;
}
